<template>
  <el-dialog :title="title" :modal-append-to-body="false" :close-on-click-modal="false" :visible.sync="showDialog"
    width="60%" class="qmDialog selectProject" @close="closeDialog">
    <grid-head-layout ref="gridHeadLayout" :search-columns="searchColumns" @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"></grid-head-layout>

    <grid-layout ref="gridLayOut" :table-options="tableOption" :table-data="tableData" :table-loading="tableLoading"
      :data-total="page.total" :page="page" @page-current-change="onLoad" @page-size-change="onLoad">
      <template #customBtn="{ row }">
        <el-button style="margin: 0 3px" type="text" size="small" @click="rowSelect(row)">选择
        </el-button>
      </template>
    </grid-layout>
  </el-dialog>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout";
import { prjbasicinfoPage } from "@/api/safe/itemList";
export default {
  components: {
    GridLayout
  },
  data() {
    return {
      showDialog: false,
      title: "",
      searchColumns: [
        {
          label: '项目名称',
          prop: "prjCode",
          span: 4,
          placeholder: '项目名称或编号'
        },
        // {
        //   label: '项目状态',
        //   prop: "prjState",
        //   span: 4,
        //   type: 'select',
        //   placeholder: '请选择项目状态',
        //   dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=prj_state`,
        //   props: {
        //     label: 'dictValue',
        //     value: 'dictKey',
        //   },
        // },
        {
          label: '立项日期',
          prop: "prjDate",
          span: 4,
          type: 'date-format',
          placeholder: '请选择立项日期',
        }
      ],
      tableLoading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      selectionList: [],
      tableOption: {
        selection: false,
        column: [
          {
            label: "项目编码",
            prop: "prjCode",
            align: "center",
            overHidden: true,
            width: 200
          },
          {
            label: "项目名称",
            prop: "prjName",
            align: "center",
            overHidden: true,
            width: 200
          },
          // {
          //   label: "备案单位",
          //   prop: "contractName",
          //   align: "center",
          //   width: 150,
          //   overHidden: true
          // },
          // {
          //   label: "项目状态",
          //   prop: "prjState",
          //   align: "center",
          //   dataType: "number",
          //   width: 120,
          //   dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=prj_state`,
          //   props: {
          //     label: 'dictValue',
          //     value: 'dictKey',
          //   },
          //   overHidden: true
          // },
          {
            label: "区域",
            prop: "areName",
            align: "center",
            overHidden: true
          },
          {
            label: "立项日期",
            prop: "prjDate",
            align: "center",
            overHidden: true
          },
        ],
      },
      tableData: [],
      phone: '',
      companyCode: ''
    };
  },
  methods: {
    init(query) {
      this.showDialog = true;
      this.title = "选择项目";
      console.log('人员组织查询参数=>', query)
      this.phone = query.phone
      this.companyCode = query.companyCode
      this.onLoad(this.page, query);
    },
    // 搜索
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.searchForm = searchForm;
      this.onLoad(this.page, searchForm);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.searchForm = {};
      this.onLoad(this.page, searchForm);
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      if (this.phone) params.phone = this.phone
      if (this.companyCode) params.companyCode = this.companyCode
      prjbasicinfoPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.searchForm)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = res.data.data.total;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
        //重新渲染表格，解决勾选框错行问题
        this.$nextTick(() => {
          this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
        });
        this.$refs.gridLayOut.selectionClear();
      });
    },
    // 选择项目
    rowSelect(row) {
      this.showDialog = false;
      console.log('row', row)
      this.$emit('callback', row)
    },
    closeDialog() {
      this.$refs.gridHeadLayout.searchForm = {};
      this.searchForm = {};
      this.showDialog = false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep.selectProject .avue-crud .el-table {
  height: 55vh !important;
  max-height: 55vh !important;
}
</style>
