<template>
  <el-dialog :title="title" :modal-append-to-body="false" :close-on-click-modal="false" :visible.sync="showDialog"
    width="60%" class="qmDialog relatedPersonnelDialog" @close="closeDialog">
    <grid-head-layout ref="gridHeadLayout" :search-columns="searchColumns" @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"></grid-head-layout>

    <grid-layout ref="gridLayOut" :table-options="tableOption" :table-data="tableData" :table-loading="tableLoading"
      :data-total="page.total" :page="page" @page-current-change="onLoad" @page-size-change="onLoad">
      <template slot="birthday" slot-scope="{ row }">
        <span>
          {{ row.idCoding && row.idCoding != '' ? getAge(row.idCoding) : '' }}
        </span>
      </template>
      <template #customBtn="{ row }">
        <el-button style="margin: 0 3px" type="text" size="small" @click="rowSelect(row)">选择
        </el-button>
      </template>
    </grid-layout>
  </el-dialog>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout";
import { getList } from "@/api/safe/thirduser";
import { getAgeFromIdCard } from "@/util/util";
export default {
  components: {
    GridLayout
  },
  data() {
    return {
      showDialog: false,
      title: "",
      searchColumns: [
        {
          prop: "userName",
          span: 6,
          placeholder: "姓名或身份证号",
        },
        {
          prop: "updateTime",
          span: 6,
          type: 'date',
          format: "yyyy-MM-dd",
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          placeholder: "请选择维护日期",
        },
      ],
      tableLoading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      selectionList: [],
      tableOption: {
        selection: false,
        column: [
          {
            label: "姓名",
            prop: "userName",
            align: "center",
            overHidden: true
          },
          {
            label: "身份证号码",
            prop: "idCoding",
            align: "center",
            overHidden: true
          },
          {
            label: "性别",
            prop: "sex",
            align: "center",
            dataType: 'number',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=project_sex_type",
          },
          {
            label: "年龄",
            prop: "birthday",
            width: 60,
            align: "center",
            slot: true,
            overHidden: true
          },
          {
            label: "籍贯",
            prop: "domicile",
            align: "center",
            overHidden: true
          },
          {
            label: "维护日期",
            prop: "updateTime",
            align: "center",
            overHidden: true
          },
        ],
      },
      tableData: [],
      submitBtn: false,
      emptyBtn: false,
    };
  },
  methods: {
    getAge(data) {
      return getAgeFromIdCard(data)
    },
    init(query) {
      this.showDialog = true;
      this.title = "选择人员";
      this.query = query
      this.onLoad(this.page, {});
    },
    // 搜索
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.searchForm = searchForm;
      this.onLoad(this.page, searchForm);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.searchForm = {};
      this.onLoad(this.page, searchForm);
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      getList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.searchForm, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = res.data.data.total;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
        //重新渲染表格，解决勾选框错行问题
        this.$nextTick(() => {
          this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
        });
      });
    },
    // 选择公司
    rowSelect(row) {
      this.showDialog = false;
      this.$emit('callback', row)
    },
    closeDialog() {
      this.$refs.gridHeadLayout.searchForm = {};
      this.searchForm = {};
      this.showDialog = false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep.relatedPersonnelDialog .avue-crud .el-table {
  height: 55vh !important;
  max-height: 55vh !important;
}
</style>
