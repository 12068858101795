var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100%", "background-color": "#ffffff" } },
    [
      _c("head-layout", {
        attrs: {
          "head-title": "相关方出入境",
          "head-btn-options": _vm.headBtnOptions,
        },
        on: { "head-saveBack": _vm.headSave, "head-cancel": _vm.headCancel },
      }),
      _c("div", { staticClass: "formContentBox" }, [
        _c(
          "div",
          { staticClass: "formMain", staticStyle: { padding: "0 12px" } },
          [
            _c(
              "el-form",
              {
                ref: "dataForm",
                attrs: {
                  model: _vm.dataForm,
                  "label-width": "150px",
                  disabled: _vm.formType == "view",
                  rules: _vm.rules,
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "formTopic",
                    staticStyle: { "margin-bottom": "12px" },
                  },
                  [_vm._v("基础信息")]
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "姓名", prop: "partnerUserName" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请选择姓名",
                                clearable: "",
                              },
                              on: {
                                focus: _vm.selectPeople,
                                clear: function ($event) {
                                  return _vm.handleClear(
                                    "phone",
                                    "partnerQuery",
                                    "projectQuery"
                                  )
                                },
                              },
                              model: {
                                value: _vm.dataForm.partnerUserName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "partnerUserName", $$v)
                                },
                                expression: "dataForm.partnerUserName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "隶属组织", prop: "partnerName" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请选择隶属组织",
                                clearable: "",
                              },
                              on: {
                                focus: _vm.handlePartner,
                                clear: function ($event) {
                                  return _vm.handleClear(
                                    "companyCode",
                                    "relatedPershonQuery",
                                    "projectQuery"
                                  )
                                },
                              },
                              model: {
                                value: _vm.dataForm.partnerName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "partnerName", $$v)
                                },
                                expression: "dataForm.partnerName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "行程状态", prop: "tripState" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择行程状态" },
                                model: {
                                  value: _vm.dataForm.tripState,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "tripState", $$v)
                                  },
                                  expression: "dataForm.tripState",
                                },
                              },
                              _vm._l(_vm.trip_state, function (item) {
                                return _c("el-option", {
                                  key: item.dictKey,
                                  attrs: {
                                    label: item.dictValue,
                                    value: item.dictKey,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "出发地", prop: "startPlace" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请选择出发地" },
                              on: {
                                focus: function ($event) {
                                  return _vm.handleRegionOPen("startPlace")
                                },
                              },
                              model: {
                                value: _vm.dataForm.startPlace,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "startPlace", $$v)
                                },
                                expression: "dataForm.startPlace",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "目的地", prop: "endPlace" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请选择目的地" },
                              on: {
                                focus: function ($event) {
                                  return _vm.handleRegionOPen("endPlace")
                                },
                              },
                              model: {
                                value: _vm.dataForm.endPlace,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "endPlace", $$v)
                                },
                                expression: "dataForm.endPlace",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "出发日期", prop: "startDate" } },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                type: "date",
                                "value-format": "yyyy-MM-dd",
                                placeholder: "请选择日期",
                              },
                              model: {
                                value: _vm.dataForm.startDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "startDate", $$v)
                                },
                                expression: "dataForm.startDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "服务项目", prop: "prjName" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请选择服务项目",
                                clearable: "",
                              },
                              on: {
                                focus: _vm.handleSelectProject,
                                clear: function ($event) {
                                  return _vm.handleClear(
                                    "prjId",
                                    "relatedPershonQuery",
                                    "partnerQuery"
                                  )
                                },
                              },
                              model: {
                                value: _vm.dataForm.prjName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "prjName", $$v)
                                },
                                expression: "dataForm.prjName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "备注", prop: "remark" } },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                autosize: { minRows: 2 },
                                "show-word-limit": "",
                                maxlength: "200",
                                placeholder: "请填写备注",
                              },
                              model: {
                                value: _vm.dataForm.remark,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "remark", $$v)
                                },
                                expression: "dataForm.remark",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  "border-bottom": "1px solid rgb(187, 187, 187)",
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "space-between",
                  "margin-bottom": "8px",
                  "padding-bottom": "8px",
                },
              },
              [
                _c("div", [_vm._v("附件")]),
                _c(
                  "el-upload",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.hideUpload,
                        expression: "hideUpload",
                      },
                    ],
                    ref: "uploadRef",
                    attrs: {
                      action:
                        "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/km",
                      "show-file-list": false,
                      "on-success": _vm.handleSuccess,
                      "before-upload": _vm.handleBeforeUpload,
                      headers: _vm.headers,
                    },
                  },
                  [
                    _c("el-button", { attrs: { size: "small", plain: "" } }, [
                      _vm._v("上传"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "shuttleBackBox" },
              [
                _c("grid-layout", {
                  ref: "gridLayOut",
                  attrs: {
                    "table-options": _vm.tableOption,
                    "table-data": _vm.fileList,
                    "table-loading": _vm.tableLoading,
                    "data-total": _vm.page.total,
                    page: _vm.page,
                  },
                  on: {
                    "page-current-change": _vm.onLoad,
                    "page-size-change": _vm.onLoad,
                    "page-refresh-change": _vm.onLoad,
                    "grid-row-detail-click": _vm.rowView,
                  },
                  scopedSlots: _vm._u(
                    [
                      _vm.hideUpload
                        ? {
                            key: "customBtn",
                            fn: function ({ row }) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { margin: "0 3px" },
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.rowDonlowd(row)
                                      },
                                    },
                                  },
                                  [_vm._v("下载")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { margin: "0 3px" },
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.rowRemove(row)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ]
                            },
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("relatedPersonnelDialog", {
        ref: "relatedPersonnelDialog",
        on: { callback: _vm.realtedPerCallback },
      }),
      _c("selectProject", {
        ref: "selectProject",
        on: { callback: _vm.selectProjectCallback },
      }),
      _c("selectPartner", {
        ref: "selectPartner",
        on: { callback: _vm.selectPartnerCallback },
      }),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          staticClass: "region-dialog",
          attrs: {
            title: `请选择${_vm.regionTitle}`,
            visible: _vm.regionModel,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.regionModel = $event
            },
          },
        },
        [
          _c("avue-tree", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.treeLoading,
                expression: "treeLoading",
              },
            ],
            ref: "avueTree",
            attrs: { option: _vm.treeOption, data: _vm.treeData },
            on: { "node-click": _vm.nodeClick },
          }),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dialogLoading,
                      expression: "dialogLoading",
                    },
                  ],
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCancel()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) +
                      "\n      "
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dialogLoading,
                      expression: "dialogLoading",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleSave },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
          attrs: {
            title: "附件预览",
            modal: true,
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            visible: _vm.prviewDialogVisible,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.prviewDialogVisible = $event
            },
          },
        },
        [
          _c("iframe", {
            ref: "fileIframe",
            staticStyle: { width: "100%", height: "500px" },
            attrs: { src: _vm.prviewDialogImageUrl, frameborder: "0" },
          }),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.prviewDialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }